import { render, staticRenderFns } from "./ActiveFeatureCard.vue?vue&type=template&id=49753896&scoped=true&"
import script from "./ActiveFeatureCard.vue?vue&type=script&lang=js&"
export * from "./ActiveFeatureCard.vue?vue&type=script&lang=js&"
import style0 from "./ActiveFeatureCard.vue?vue&type=style&index=0&id=49753896&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49753896",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardText,VColorPicker,VIcon})
