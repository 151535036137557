<template>
  <div class="card__container w-full">
    <v-card
      :class="'card bg__' + card.theme"
      style="background:transparent"
    >
      <v-card-title class="title mb-4">
        <v-avatar :size="card.selectedSize.height > 630 ? '160' : '90'">
          <v-img :src="tweet.user.profile_image_url"></v-img>
        </v-avatar>
        <div class="mt-5 user">
          <div class="name mb-3 ">
            {{ tweet.user.name }}

            <v-icon
              v-if="tweet.user.verified"
              class="twitter__verified"
            >
              {{ icons.mdiCheckDecagram }}
            </v-icon>
          </div>

          <small class="username">@{{ tweet.user.username }}</small>
        </div>
      </v-card-title>

      <div class="content__container">
        <v-card-text
          ref="tweetContent"
          :style="styles"
          class="content hide__overflow"
          v-html="tweet.text"
        ></v-card-text>

        <div
          v-if="tweet.media && card.selectedSize.height > 1080"
          class="media"
        >
          <img
            v-for="media in tweet.media"
            :key="media.id"
            :src="media.url"
          >
        </div>

        <v-card-text
          v-if="card.showDate"
          class="date mt-2 "
        >
          {{ formatedCreatedAt }}
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 400,
          fontSize: 28,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 700,
          fontSize: 28,
          minFontSize: 12,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 44,
          minFontSize: 14,
        },
      ],
      socialIcons: {
        comment: require('@/assets/images/svg/comment.svg'),
        shares: require('@/assets/images/svg/shares.svg'),
        heart: require('@/assets/images/svg/heart.svg'),
      },
    }
  },
  computed: {
    ...mapState({
      activeBackground: state => state.social.activeBackground,
    }),
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweetshots/theme.scss';

.card {
  width: 100%;
  height: 100%;
  max-width: 100%;
  box-shadow: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .twitter__icon {
    position: absolute;
    top: 10%;
    right: 10%;
  }

  .title {
    width: 80%;
    place-self: center;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid white;
    .user {
      font-size: 1.5vw;
    }
    .username {
      font-size: 1.2vw;
      font-style: normal;
      font-weight: normal;
    }
  }
}

.bg__light {
  .name,
  .date,
  .username,
  .content {
    color: #ffffff;
  }
}

.card__container {
  overflow: hidden;
}

.date {
  margin-top: 2rem;
}
//size variation

.height__1080,
.height__1920 {
  .twitter__verified {
    transform: scale(1.6);
    width: 44px;
  }
  .twitter__icon {
    transform: scale(3);
  }

  .title {
    .user {
      .name {
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 100%;
      }
      .username {
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 100%;
      }
    }
  }

  // .content__container {
  //   padding: 2rem 5rem !important;

  // }

  .date {
    font-size: 28pt;
  }
}

.height__1080 {
  .title {
    .user {
      .name {
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 100%;
      }
      .username {
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 100%;
      }
    }
  }
  // .content__container {
  //   padding: 1rem !important;

  // }
}
</style>
