<template>
  <div class="card__container">
    <v-card :class="'card card__background w-full p-2 tweet__theme card__background disable_bg__' + card.theme ">
      <div
        ref="tweetContent"
        class="content hide__overflow"
        v-html="tweet.text"
      ></div>

      <div
        v-if="tweet.media && card.selectedSize.height > 1080"
        class="media"
      >
        <img
          v-for="media in tweet.media"
          :key="media.id"
          :src="media.url"
        >
      </div>

      <div class="card__footer text__secondary ">
        <v-icon
          class="twitter__icon"
        >
          {{ icons.mdiTwitter }}
        </v-icon>
        Tweet by <strong>{{ tweet.user.name }}</strong>
        <span
          v-if="card.showDate"
        > on {{ formatedCreatedAt }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 450,
          fontSize: 22,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 800,
          fontSize: 28,
          minFontSize: 12,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 44,
          minFontSize: 14,
        },
      ],
      socialIcons: {
        comment: require('@/assets/images/svg/comment.svg'),
        shares: require('@/assets/images/svg/shares.svg'),
        heart: require('@/assets/images/svg/heart.svg'),
      },
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweetshots/theme.scss';

.bg__light {
  .content {
    color: #333333;
  }
}
.bg__dark {
  background: #28243d;

  .content {
    color: white;
  }
}
.card__container {
  width: 100%;
  padding: 5%;
}
.card {
  padding: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .content {
    // font-family: Poppins;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .card__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    color: #536471;
    background: #eef2f5;
    border-radius: 0px 0px 48px 48px;
    padding: 20px 3rem;

    font-size: 12pt;
    .twitter__icon {
      width: 37px;
      position: relative;
      top: -3px;
      transform: scale(1.2);
    }
    .text-primary {
      color: #1ea0f1;
    }
  }
  .media {
    text-align: center;
  }
}
.tweet__theme {
  max-height: 100%;
  background: #ffffff;
  /* card/hover */

  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888);
  border-radius: 48px;
  .user {
    color: #333333;
    .v-icon {
      margin-left: 5px;
    }
    .name {
      font-style: normal;
      font-weight: bold;
    }
    .username {
      font-weight: 300;
    }
  }
}

//size variation

.height__1080 {
  .card__footer {
    font-size: 18pt;
    .twitter__icon {
      width: 44px;
      transform: scale(1.4);
    }
  }
}
.height__1920 {
  .card__footer {
    font-size: 20pt;
    .twitter__icon {
      width: 44px;
      transform: scale(1.4);
    }
  }
}
</style>
