<template>
  <v-row>
    <v-col
      v-for="item in themesOptionsPaginated"
      :key="item.id"
      cols="6"
    >
      <v-card
        class="pa-2 pb-0 pointer"
        outlined
        tile
        @click="switchTheme(item)"
      >
        <v-img
          height="150"
          :src="item.thumb"
        ></v-img>
        <v-card-text class="text-center mb-0 pb-1">
          {{ item.title }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      class="text-right"
    >
      <v-pagination
        v-model="themePage"
        :length="3"
        circle
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import socialDB from '@/@fake-db/data/socialDB'

export default {
  props: ['switchTheme'],
  data() {
    return {
      themePage: 1,
      pageSize: 4,
      themesOptions: socialDB.twitterThemes,
    }
  },
  computed: {
    indexStart() {
      return (this.themePage - 1) * this.pageSize
    },
    indexEnd() {
      return this.indexStart + this.pageSize
    },
    themesOptionsPaginated() {
      return this.themesOptions.slice(this.indexStart, this.indexEnd)
    },
  },
}
</script>
