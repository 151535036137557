<template>
  <div>
    <div
      v-if="!activeFeature"
    >
      <FeatureCards :active-feature.sync="activeFeature" />
      <BackgroundsCard />
    </div>
    <div
      v-else
    >
      <ActiveFeatureCard :active-feature.sync="activeFeature" />
    </div>
  </div>
</template>

<script>
import FeatureCards from './FeatureCards.vue'
import BackgroundsCard from './BackgroundsCard.vue'
import ActiveFeatureCard from './ActiveFeatureCard.vue'

export default {
  components: {
    FeatureCards,
    BackgroundsCard,
    ActiveFeatureCard,
  },
  data() {
    return {
      activeFeature: null,
    }
  },
}
</script>

<style scoped lang="scss">
.colors {
  .feature__card {
    cursor: pointer;
    min-width: 7rem;
    .v-card__title {
      font-size: 0.9rem;
      padding: 10px;
    }
    .v-card__subtitle {
      font-size: 0.8rem;
      padding: 10px;
    }
  }

  .box {
    cursor: pointer;
    border-radius: 50%;
    padding: 1px;
    width: 40px;
    height: 40px;
    border: 1px solid grey;
    display: flex;
    &:hover {
      border: 2px solid black;
    }
  }
}
</style>
