<template>
  <div class="app__sidebar">
    <!-- card settings -->
    <div class="section ">
      <label>Card settings</label>
      <div class="card__settings">
        <div class="action-box d-flex flex-column align-center ">
          <div
            class="icon"
            :class="isActionDisabled('card') ? 'disabled' : ''"
            @click="isActionDisabled('card') ? '' : toggleCardTheme() "
          >
            <v-icon
              v-if="card.theme == 'light'"
              class="rotate icon"
            >
              {{ icons.mdiMoonWaningCrescent }}
            </v-icon>
            <v-icon
              v-else
              class="icon"
            >
              {{ icons.mdiWhiteBalanceSunny }}
            </v-icon>
          </div>
          <span>{{ card.theme == 'light' ? 'Dark' : 'Light' }} </span>
        </div>

        <div class="action-box d-flex flex-column align-center ">
          <div
            class="icon"
            :class="isActionDisabled('date') ? 'disabled' : ''"
            @click="isActionDisabled('date') ? '' : toggleDate()"
          >
            <v-icon
              v-if="card.showDate"
              width="50"
              size="25"
            >
              {{ icons.mdiClockOutline }}
            </v-icon>
            <v-icon
              v-else
              width="50"
              size="25"
            >
              {{ icons.mdiClockOut }}
            </v-icon>
          </div>
          <span :class="card.showDate ? '' : 'text-decoration-line-through'">Time</span>
        </div>

        <div class="action-box d-flex flex-column align-center ">
          <div
            class="icon"
            :class="isActionDisabled('response') ? 'disabled' : ''"
            @click="isActionDisabled('response') ? '' : toggleResponse()"
          >
            <v-icon
              v-if="card.response"
              width="50"
              size="25"
            >
              {{ icons.mdiHeart }}
            </v-icon>
            <v-icon
              v-else
              width="50"
              size="25"
            >
              {{ icons.mdiHeartOutline }}
            </v-icon>
          </div>
          <span :class="card.response ? '' : 'text-decoration-line-through'">Engagement</span>
        </div>

        <div class="action-box d-flex flex-column align-center">
          <div
            class="icon"
            :class="isActionDisabled('shadow') ? 'disabled' : ''"
            @click="isActionDisabled('shadow') ? '' : toggleShadow()"
          >
            <v-icon
              width="50"
              size="25"
            >
              {{ icon.mdiBoxShadow }}
            </v-icon>
          </div>
          <span :class="card.shadow ? '' : 'text-decoration-line-through'"> Card shadow</span>
        </div>
      </div>
    </div>

    <!-- post size section -->
    <div class="section ">
      <label>Post size</label>
      <div class="card__size">
        <div
          v-for="(size,index) in sizes"
          :key="index"
          :class="`size__box  ${card.selectedSize.height === size.height ? 'active' : ''}`"
          @click="selectSize(size)"
        >
          <div :class="`box ${size.class}`"></div>
          {{ size.title }}
        </div>
      </div>
    </div>

    <v-expansion-panels class="pa-1">
      <!-- themes accordin -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          Themes (10)
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ThemePanel :switch-theme="switchTheme" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- backgrounds -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          Colors
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ColorsPanel />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Fonts
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <fonts-panel />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- colors accordin -->
    <!-- fonts accordin -->
  </div>
</template>

<script>
import { mdiBoxShadow } from '@mdi/js'
import FontsPanel from './FontsPanel.vue'
import ThemePanel from './ThemePanel.vue'
import ColorsPanel from './ColorsPanel/Index.vue'

export default {
  components: {
    FontsPanel,
    ThemePanel,
    ColorsPanel,
  },
  props: ['icons', 'switchTheme', 'toggleCardTheme', 'isActionDisabled', 'card', 'sizes', 'selectSize'],
  data() {
    return {
      icon: {
        mdiBoxShadow,
      },
    }
  },
  methods: {
    toggleResponse() {
      this.card.response = !this.card.response
      this.$emit('update:card', this.card)
    },
    toggleDate() {
      this.card.showDate = !this.card.showDate
      this.$emit('update:card', this.card)
    },
    toggleShadow() {
      this.card.shadow = !this.card.shadow
      this.$emit('update:card', this.card)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/tools-sidebar.scss';
</style>
