<template>
  <div class="card__container">
    <v-card
      :class="'card card__background dbg__' + card.theme "
    >
      <v-icon class="twitter__icon">
        {{ icons.mdiTwitter }}
      </v-icon>

      <div class="content__container">
        <v-card-text
          ref="tweetContent"
          class="content hide__overflow"
          :style="styles"
          v-html="tweet.text"
        ></v-card-text>

        <div
          v-if="tweet.media && card.selectedSize.height > 1080"
          class="media"
        >
          <img
            v-for="media in tweet.media"
            :key="media.id"
            :src="media.url"
          >
        </div>

        <v-card-text
          v-if="card.showDate"
          class="date"
        >
          {{ formatedCreatedAt }}
        </v-card-text>
      </div>

      <v-card-title class="title">
        <v-avatar :size="card.selectedSize.height > 630 ? '96' : '56'">
          <v-img :src="tweet.user.profile_image_url"></v-img>
        </v-avatar>
        <div class="ml-5 mt-1 user">
          <div class="name ">
            {{ tweet.user.name }}

            <v-icon
              v-if="tweet.user.verified"
              class="twitter__verified"
            >
              {{ icons.mdiCheckDecagram }}
            </v-icon>
          </div>

          <small class="username">@{{ tweet.user.username }}</small>
        </div>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 340,
          fontSize: 28,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 650,
          fontSize: 34,
          minFontSize: 12,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 44,
          minFontSize: 14,
        },
      ],
      socialIcons: {
        comment: require('@/assets/images/svg/comment.svg'),
        shares: require('@/assets/images/svg/shares.svg'),
        heart: require('@/assets/images/svg/heart.svg'),
      },
    }
  },
  computed: {
    ...mapState({
      activeBackground: state => state.social.activeBackground,
    }),
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweetshots/theme.scss';

.card__container {
  width: 100%;
  padding: 5%;
}
.card {
  width: 100%;
  height: 100%;
  max-width: 100%;

  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888) !important;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  .twitter__verified {
    color: #1ea0f1;
  }
  .twitter__icon {
    color: #1ea0f1;
    position: absolute;
    top: 10%;
    right: 10%;
    transform: scale(2);
  }

  .title {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 2rem;
  }
  .content__container {
    padding: 2em;
    overflow: hidden;
  }
}

.bg__light {
  background: white;
  .name,
  .date,
  .username,
  .content {
    color: #333333;
  }
}

//size variations
.height__1080,
.height__1920 {
  .twitter__verified {
    transform: scale(1.6);
    width: 44px;
  }
  .twitter__icon {
    transform: scale(3);
  }

  .title {
    .user {
      .name {
        font-size: 32pt;
        margin-bottom: 10px;
      }
      .username {
        font-size: 22pt;
      }
      .twitter__verified {
        transform: scale(1.8);
      }
    }
  }

  .content__container {
    padding: 2rem !important;
  }

  .date {
    margin-top: 2rem;

    font-size: 24pt;
  }
}

.height__1080 {
  .content__container {
    padding: 2rem !important;
  }
}
</style>
