<template>
  <v-card
    :class="'tweet__card  w-full p-2 tweet__theme__default card__background disable_tweet__card__' + card.theme "
  >
    <v-card-title>
      <v-avatar :size="card.selectedSize.height > 630 ? '110' : '66'">
        <v-img :src="tweet.user.profile_image_url"></v-img>
      </v-avatar>
      <p
        class="ml-3 mt-3 tweet__username"
      >
        {{ tweet.user.name }}
        <v-icon
          v-if="tweet.user.verified"
          class="twitter__verified"
        >
          {{ icons.mdiCheckDecagram }}
        </v-icon>
        <br>
        <small class="username">@{{ tweet.user.username }}</small>
      </p>
    </v-card-title>

    <v-card-text
      ref="tweetContent"
      class="tweet__content transition"
      :style="styles"
      v-html="tweet.text"
    ></v-card-text>

    <v-card-text
      class="card__text date"
      :style="styles"
    >
      <span v-if="card.showDate">{{ formatedCreatedAt }}</span>
    </v-card-text>
    <div
      v-if="tweet.media && card.selectedSize.height > 1080"
      class="media"
    >
      <img
        v-for="media in tweet.media"
        :key="media.id"
        :src="media.url"
      >
    </div>

    <v-card-text
      v-if="card.response"
      class="d-flex card__text response"
      :style="styles"
    >
      <div>
        <span
          class="font__medium text__primary"
          :style="styles"
        >{{ tweet.public_metrics.reply_count }}</span> <span class="text__secondary">replies</span>
      </div>
      <div class="mx-4">
        <span
          class="font__medium text__primary"
          :style="styles"
        >{{ tweet.public_metrics.retweet_count }}</span>  <span class="text__secondary">shares</span>
      </div>
      <div>
        <span
          class="font__medium text__primary"
          :style="styles"
        >{{ tweet.public_metrics.like_count }}</span>  <span class="text__secondary">likes</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 380,
          fontSize: 18,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 680,
          fontSize: 24,
          minFontSize: 14,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 36,
          minFontSize: 14,
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweet-task.scss';
@import '@/styles/tweetshots/theme.scss';

//setting out color scheme

.tweet__content {
  overflow: hidden;
}
.height__630 {
  .tweet__card {
    width: 45rem;
    .tweet__content {
      font-size: 18pt;
    }
    .date,
    .response {
      font-size: 1.2em;
    }
  }
}

.height__1080,
.height__1920 {
  .tweet__card {
    max-width: 90%;
    .tweet__content {
      font-size: 2.5em;
    }
    .tweet__username {
      font-size: 1.5em;
    }
    .twitter__verified {
      transform: scale(1.5);
      width: 50px;
    }
    .date,
    .response {
      font-size: 1.5em;
    }
  }
}
</style>
