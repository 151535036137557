<template>
  <div>
    <div>
      <v-btn
        icon
        title="Go back to colors"
        @click="$emit('update:active-feature',null)"
      >
        <v-icon>{{ icons.mdiArrowLeftThick }}</v-icon>
      </v-btn>
      {{ activeFeature.title }}
    </div>
    <!-- color picker -->

    <v-card
      outlined
      class="mt-2"
    >
      <div
        class="d-flex justify-center ma-4"
      >
        <v-color-picker
          v-model="customColor"
          dot-size="20"
          swatches-max-height="200"
          mode="hexa"
        ></v-color-picker>
      </div>
    </v-card>

    <!-- default colors -->

    <v-card
      outlined
      class="mt-2"
    >
      <v-card-text
        class="colors d-flex flex-wrap "
      >
        <div
          v-for="(val,index) in colorOptions"
          :key="index"
          class="box ml-1"
          :style="`background:${val}`"
          @click="setCustomcolor(val)"
        ></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteCircle, mdiClose, mdiArrowLeftThick } from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  props: {
    activeFeature: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      colorOptions: ['#000000', '#ffffff', '#ffe700', '#3aa8e4', '#c61044', '#9fc54d'],
      customColor: null,
      icons: {
        mdiPlus,
        mdiDeleteCircle,
        mdiArrowLeftThick,
        mdiClose,
      },
    }
  },

  watch: {
    customColor(val) {
      const payload = {
        key: this.activeFeature.key,
        val: typeof val === 'object' ? val.hex : val,
      }

      // if (this.activeFeature.key === 'backgroundColor') {
      //   payload.val = typeof val === 'object' ? `background:${val.hex}` : `background:${val}`
      // }
      this.setColorScheme(payload)
    },
  },

  created() {
    this.customColor = this.activeFeature.color
    this.setColorScheme(this.activeFeature)
  },

  methods: {
    ...mapActions('social', ['setColorScheme']),
    setCustomcolor(val) {
      console.log('setting custom color', val)
      this.customColor = val
    },
  },
}
</script>

<style scoped lang="scss">
.colors {
  .feature__card {
    cursor: pointer;
    min-width: 7rem;
    .v-card__title {
      font-size: 0.9rem;
      padding: 10px;
    }
    .v-card__subtitle {
      font-size: 0.8rem;
      padding: 10px;
    }
  }

  .box {
    cursor: pointer;
    border-radius: 50%;
    padding: 1px;
    width: 40px;
    height: 40px;
    border: 1px solid grey;
    display: flex;
    &:hover {
      border: 2px solid black;
    }
  }
}
</style>
