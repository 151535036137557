<template>
  <div class="card__container">
    <v-card
      :class="`card  bg__${card.theme}`"
      style="background:transparent;"
    >
      <v-icon class="twitter__icon">
        {{ icons.mdiTwitter }}
      </v-icon>

      <div class="content__container">
        <v-card-text
          ref="tweetContent"
          class="content hide__overflow"
          v-html="tweet.text"
        ></v-card-text>

        <div
          v-if="tweet.media && card.selectedSize.height > 1080"
          class="media"
        >
          <img
            v-for="media in tweet.media"
            :key="media.id"
            :src="media.url"
          >
        </div>

        <v-card-text
          v-if="card.showDate"
          class="date "
        >
          {{ formatedCreatedAt }}
        </v-card-text>
      </div>

      <v-card-title class="title">
        <v-avatar :size="card.selectedSize.height > 630 ? '96' : '56'">
          <v-img :src="tweet.user.profile_image_url"></v-img>
        </v-avatar>
        <div
          class="ml-5 mt-1 user text__primary"
        >
          <div
            class="name text__primary"
          >
            {{ tweet.user.name }}

            <v-icon
              v-if="tweet.user.verified"
              class="twitter__verified"
            >
              {{ icons.mdiCheckDecagram }}
            </v-icon>
          </div>

          <small
            class="username"
          >@{{ tweet.user.username }}</small>
        </div>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 400,
          fontSize: 34,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 700,
          fontSize: 28,
          minFontSize: 12,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 44,
          minFontSize: 14,
        },
      ],
      socialIcons: {
        comment: require('@/assets/images/svg/comment.svg'),
        shares: require('@/assets/images/svg/shares.svg'),
        heart: require('@/assets/images/svg/heart.svg'),
      },
    }
  },
  computed: {
    ...mapState({
      activeBackground: state => state.social.activeBackground,
    }),
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweetshots/theme.scss';

.card__container {
  min-width: 100%;
}
.card {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100%;
  box-shadow: 0;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .twitter__icon {
    position: absolute;
    top: 10%;
    right: 10%;
    transform: scale(2);
  }

  .title {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 2rem;
  }
  .content__container {
    padding: 2em;
  }
  .date {
    font-size: 1vw;
  }
}

.bg__light {
  .name,
  .date,
  .username,
  .content {
    color: #333333;
  }
}

//size variations
.height__1080,
.height__1920 {
  .twitter__verified {
    transform: scale(1.6);
    width: 44px;
  }
  .twitter__icon {
    transform: scale(3);
  }

  .title {
    .user {
      .name {
        font-size: 28pt;
        margin-bottom: 10px;
      }
      .username {
        font-size: 18pt;
      }
    }
  }

  .content__container {
    padding: 5rem !important;
  }

  .date {
    margin-top: 2rem;

    font-size: 24pt;
  }
}
.height__1080 {
  .content__container {
    padding: 2rem !important;
  }
}
</style>
