<template>
  <div
    class="colors d-flex flex-wrap"
  >
    <v-card
      v-for="(item,i) in featureCards"
      :key="i"
      class=" feature__card"
      outlined
      @click="$emit('update:active-feature',item)"
    >
      <div class="px-2 pt-3 mb-0">
        <div
          class="box"
          :style="`background:${item.color}`"
        ></div>
      </div>
      <v-card-title>
        {{ item.title }}
      </v-card-title>
      <v-card-subtitle>{{ item.color }}</v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    featureCards() {
      const { colorScheme } = this.$store.state.social

      return [
        {
          title: 'Background',
          key: 'backgroundColor',
          color: colorScheme.backgroundColor,
        },

        // {
        //   title: 'Card Background',
        //   key: 'cardBackgroundColor',
        //   color: colorScheme.cardBackgroundColor,
        // },
        {
          title: 'Text primary',
          key: 'textPrimary',
          color: colorScheme.textPrimary,
        },

        {
          title: 'Link',
          key: 'link',
          color: '#1B95E0',
        },
        {
          title: 'Text secondary',
          key: 'textSecondary',
          color: colorScheme.textSecondary,
        },
        {
          title: 'Verified icon',
          key: 'verifiedIcon',
          color: colorScheme.verifiedIcon,
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.colors {
  .feature__card {
    margin: 2px;
    cursor: pointer;
    min-width: 7rem;
    .v-card__title {
      font-size: 0.8rem;
      padding: 8px;
    }
    .v-card__subtitle {
      font-size: 0.7rem;
      padding: 8px;
    }
  }

  .box {
    cursor: pointer;
    border-radius: 50%;
    padding: 1px;
    width: 40px;
    height: 40px;
    border: 1px solid grey;
    display: flex;
    &:hover {
      border: 2px solid black;
    }
  }
}
</style>
