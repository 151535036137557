<template>
  <div class="card__container">
    <v-card :class="'card  w-full p-2 tweet__theme bg__' + card.theme ">
      <img
        class="twitter__bg"
        :src="socialIcons.twitter"
      >
      <v-icon
        v-if="card.selectedSize.height <= 630"
        class="twitter__icon floating__icon"
      >
        {{ icons.mdiTwitter }}
      </v-icon>

      <v-card-text
        v-if="card.showDate && card.selectedSize.height > 630"
        class="text date"
      >
        {{ formatedCreatedAt }}
      </v-card-text>

      <v-card-text
        ref="tweetContent"
        class="content hide__overflow"
        v-html="tweet.text"
      ></v-card-text>

      <div
        v-if="tweet.media && card.selectedSize.height > 1080"
        class="media"
      >
        <img
          v-for="media in tweet.media"
          :key="media.id"
          :src="media.url"
        >
      </div>

      <div class="d-flex justify-space-between px-3 mt-3">
        <div
          v-if="card.response"
          class="d-flex  text responses text__primary"
        >
          <div class="d-flex mx-4">
            <img
              class="icon"
              :src="socialIcons.comment"
              width="33"
            >
            <span class="mx-2">{{ tweet.public_metrics.reply_count }}</span>
          </div>

          <div class="d-flex mx-4">
            <img
              class="icon"
              :src="socialIcons.shares"
              width="33"
            >
            <span class="mx-4">{{ tweet.public_metrics.retweet_count }}</span>
          </div>
          <div class="d-flex">
            <img
              class="icon"
              :src="socialIcons.heart"
              width="33"
            >
            <span class="mx-4">{{ tweet.public_metrics.like_count }}</span>
          </div>
        </div>

        <div
          v-if="card.showDate && card.selectedSize.height <= 630"
          class="text date px-4"
        >
          {{ formatedCreatedAt }}
        </div>
      </div>

      <v-card-title class="title">
        <v-avatar :size="card.selectedSize.height > 630 ? '128' : '96'">
          <v-img :src="tweet.user.profile_image_url"></v-img>
        </v-avatar>
        <p class="ml-3 mt-3 user">
          <span class="name">{{ tweet.user.name }}</span>
          <v-icon
            v-if="tweet.user.verified"
            class="twitter__verified"
          >
            {{ icons.mdiCheckDecagram }}
          </v-icon>
          <br>
          <v-icon
            v-if="card.selectedSize.height > 630"
            class="twitter__icon"
          >
            {{ icons.mdiTwitter }}
          </v-icon>
          <small class="username">@{{ tweet.user.username }}</small>
        </p>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 340,
          fontSize: 28,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 700,
          fontSize: 34,
          minFontSize: 12,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 44,
          minFontSize: 14,
        },
      ],
      socialIcons: {
        comment: require('@/assets/images/svg/comment.svg'),
        shares: require('@/assets/images/svg/shares.svg'),
        heart: require('@/assets/images/svg/heart.svg'),
        twitter: require('@/assets/images/svg/twitter.svg'),
      },
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweetshots/theme.scss';

.bg__light {
  .content {
    color: #333333;
  }
}
.card__container {
  overflow: hidden;
  width: 100%;
}
.card {
  padding: 1rem !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.floating__icon {
  position: absolute;
  right: 10.42%;
  top: 10%;
  transform: scale(2);
}
.tweet__theme {
  max-height: 100%;
  background: #eef2f5;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888);
  .title {
    z-index: 2;
    .user {
      color: #333333;
      .v-icon {
        margin-left: 5px;
      }
      .name {
        font-style: normal;
        font-weight: bold;
      }
      .username {
        font-weight: 300;
      }
    }
  }
  .content {
    margin: 0 0 12px 0;
  }
  .content,
  .text {
    z-index: 2;
    color: #282f38;
  }

  .responses {
    span {
      font-size: 24px;
    }
  }
  .date {
    font-style: normal;
    font-weight: 300;
    font-size: 20pt;
    line-height: 150%;
  }

  .title {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .media {
    text-align: center;
  }
}
.twitter__bg {
  position: absolute;
  z-index: 1;
}

//related to specified Size ( based on height )

.height__630 {
  .card {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .twitter__bg {
    width: 69%;
    left: -21%;
    top: 8.18%;
  }
  .content {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
  }
}

.height__1080 {
  .card {
    padding: 3rem !important;
  }
  .twitter__bg {
    width: 191%;
    left: -61%;
    top: 7.18%;
  }
  .title {
    .user {
      .name {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 150%;
      }
      .twitter__icon {
        position: relative;
        top: -4px;
        height: 32px;
        width: 42px;
        transform: scale(1.5);
      }
      .twitter__verified {
        position: relative;
        top: -10px;
        transform: scale(2);
        width: 72px;
      }
      .username {
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
      }
    }
  }
  .content {
    font-style: normal;
    font-weight: normal;
  }
}

.height__1920 {
  .card {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 3rem !important;
    .title {
      .user {
        .name {
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 150%;
        }
        .twitter__icon {
          position: relative;
          top: -4px;
          height: 32px;
          width: 42px;
          transform: scale(1.5);
        }
        .twitter__verified {
          position: relative;
          top: -10px;
          transform: scale(2);
          width: 72px;
        }
        .username {
          font-style: normal;
          font-weight: 300;
          font-size: 32px;
        }
      }
    }
  }
  .twitter__bg {
    width: 224%;
    left: -55%;
    top: 3.18%;
  }
  .content {
    font-style: normal;
    font-weight: normal;
  }
  .date {
    font-size: 28pt;
  }
  .responses {
    span {
      font-size: 28pt;
    }
    .icon {
      transform: scale(1.2);
      width: 32px;
    }
  }
}
</style>
