<template>
  <v-app>
    <div
      class="app__main "
      :style="styleVars"
    >
      <div class="w-full h-full d-flex flex-wrap">
        <v-col
          cols="12"
          md="8"
        >
          <!-- Page Switcher -->
          <div class="app__top w-full text-center d-flex align-center border">
            <img
              class="mr-3"
              width="35"
              height="35"
              :src="require('@/assets/images/svg/twitter-icon.svg')"
            />
            <h2>
              TweetShots
            </h2>
            <!-- <PageSwitcherLinks /> -->
            <v-row>
              <v-col
                cols="12"
              >
                <input
                  v-model="url"
                  outlined
                  class="input__field"
                  placeholder="Paste your Tweet’s url here!"
                  @keyup.enter="getTweet"
                />

                <p
                  v-if="error"
                  class="text__danger mt-3"
                >
                  <v-icon class="text__danger">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                  {{ error }}
                </p>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col
              :cols="exportPreview ? '12' : '12'"
              :style="exportPreview ? `max-width:${card.selectedSize.width}px;min-width:${card.selectedSize.width}px;padding:0` : 'width:auto'"
            >
              <div class="main__container">
                <div
                  class="size__control"
                >
                  <div
                    ref="tweetExportRef"
                    :class="`app__wrapper d-flex justify-center  ${exportPreview ? '' : 'transform__' + card.selectedSize.height} height__${card.selectedSize.height} ${activeFont}`"
                    :style="`${ resolveContainerBackground };width:${card.selectedSize.width}px;height:${card.selectedSize.height}px`"
                  >
                    <component
                      :is="theme"
                      ref="theme"
                      :card="card"
                      :tweet="tweet"
                      :icons="icons"
                    >
                    </component>
                  </div>
                </div>

                <div class="d-flex justify-space-between align-baseline my-2 ">
                  <div class="font-weight-bold mx-3">
                    Usage: {{ limits.usedImages }}/{{ limits.maxImages }}
                  </div>
                  <div
                    class="text-center bg-primary"
                  >
                    <v-btn
                      class="mx-3"
                      color="primary"
                      x-large
                      @click="copyToClipBoard"
                    >
                      <v-icon class="mr-2 pointer">
                        {{ icons.mdiImage }}
                      </v-icon>
                      <label class="pointer">Copy</label>
                    </v-btn>

                    <v-btn
                      color="primary"
                      x-large
                      @click="exportImage"
                    >
                      <v-icon class="mr-2 pointer">
                        {{ icons.mdiDownload }}
                      </v-icon>
                      <label class="pointer">Download</label>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <sidebar
            :icons="icons"
            :switch-theme="switchTheme"
            :toggle-card-theme="toggleCardTheme"
            :is-action-disabled="isActionDisabled"
            :card.sync="card"
            :sizes="sizes"
            :select-size="selectSize"
          />
        </v-col>

        <!-- Contents -->
      </div>
    </div>

    <!-- additional modules -->

    <!-- dialog for loading -->
    <!-- full page loading -->
    <!-- <div
      v-if="exportPreview"
      class="loading"
    >
      <img :src="loadingSvg">
    </div> -->
    <!-- short loading -->
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar for message -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="2000"
      :light="$vuetify.theme.dark"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import Vue from 'vue'
import vuetwemoji from 'vue-twemoji'

import DefaultTheme from './components/twitter-images/themes/DefaultTheme.vue'
import Theme1 from './components/twitter-images/themes/Theme1.vue'
import Theme2 from './components/twitter-images/themes/Theme2.vue'
import Theme3 from './components/twitter-images/themes/Theme3.vue'
import Theme4 from './components/twitter-images/themes/Theme4.vue'
import Theme5 from './components/twitter-images/themes/Theme5.vue'
import Theme6 from './components/twitter-images/themes/Theme6.vue'
import Theme7 from './components/twitter-images/themes/Theme7.vue'
import Theme8 from './components/twitter-images/themes/Theme8.vue'
import Theme9 from './components/twitter-images/themes/Theme10.vue'

import apiClient from '@/services'

import Sidebar from './components/twitter-images/sidebar/Index.vue'

// load mixins
import ControlMixin from './mixins/Control'
import socialDb from '@/@fake-db/data/socialDB'

Vue.use(vuetwemoji)

export default {
  metaInfo: {
    title: 'Twitter Images',
  },
  components: {
    DefaultTheme,
    Theme1,
    Theme2,
    Theme3,
    Theme4,
    Theme5,
    Theme6,
    Theme7,
    Theme8,
    Theme9,
    Sidebar,
  },
  mixins: [ControlMixin],
  data() {
    return {
      page: 'twitter-images',
      actionsDisabled: [],
      themes: socialDb.twitterThemes,
      defaultSettings: {
        themeBackground: 'light',
        background: 'background-image: linear-gradient(150deg, rgb(95, 108, 138), rgb(48, 59, 94), rgb(14, 18, 38))',
      },
      tweet: {
        text: 'Welcome to TweetShots.\n Start by pasting the tweet url above 👆 👆 👆 \n \n Repurposing your best tweets across every channel has never been so easy and fun!',
        public_metrics: {
          retweet_count: '4.2k',
          like_count: '2.5k',
          quote_count: 61,
          reply_count: 72,
        },

        created_at: '2022-03-23T00:38:20.000Z',
        user: {
          profile_image_url: require('@/assets/images/tweetshot/avatar.png'),
          username: 'MassimoCw',
          name: 'Massimo Chieruzzi',
          verified: true,
        },
        media: [],
      },

      url: null,
    }
  },

  computed: {
    styleVars() {
      const { colorScheme } = this.$store.state.social

      return {
        '--text-primary': colorScheme.textPrimary,
        '--text-secondary': colorScheme.textSecondary,
        '--link-color': colorScheme.link,
        '--icon-color': colorScheme.verifiedIcon,
        '--background-color': colorScheme.backgroundColor,
        '--card-background-color': colorScheme.cardBackgroundImage
          ? colorScheme.cardBackgroundImage
          : colorScheme.cardBackgroundColor,
        '--box-shadow': this.card.shadow
          ? '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'
          : 'none',
      }
    },
  },

  mounted() {
    this.fitContent()
  },

  methods: {
    // switch current theme with selected
    switchTheme(theme) {
      this.setLog(true)
      this.themeSelectionEnable = false
      this.theme = theme.id
      this.actionsDisabled = theme.actions

      // apply theme color scheme
      if (theme.settings) {
        // set default background color
        this.card.theme = theme.settings.themeBackground || this.defaultSettings.themeBackground
        this.applyDefaultBackground(theme.settings.background || this.defaultSettings.background)
      }
      let { colorScheme } = theme
      if (this.card.theme === 'dark' && theme.darkColorScheme) colorScheme = theme.darkColorScheme
      this.setColorSchemeWrapper(colorScheme)

      this.notify('Theme applied')

      setTimeout(() => {
        this.fitContent()
      }, 500)
    },

    setColorSchemeWrapper(colorScheme) {
      if (colorScheme) {
        Object.keys(colorScheme).forEach(key => {
          this.setColorScheme({
            key,
            val: colorScheme[key],
          })
        })
      }
    },

    isActionDisabled(action) {
      return this.actionsDisabled.includes(action)
    },

    // fetch twitter post information
    getTweet() {
      const segments = this.url.split('/')
      if (segments.length !== 6) {
        this.error = 'Invalid url'

        return
      }
      const tweetId = segments[5].split('?')[0]
      this.loadingToggle()

      apiClient
        .getTweet(tweetId)
        .then(res => {
          const { data, includes } = res.data
          const tweet = {
            text: this.parseEmoji(this.processText(data)),
            public_metrics: {
              retweet_count: this.kFormatter(data.public_metrics.retweet_count),
              like_count: this.kFormatter(data.public_metrics.like_count),
              quote_count: this.kFormatter(data.public_metrics.quote_count),
              reply_count: this.kFormatter(data.public_metrics.reply_count),
            },
            created_at: data.created_at,
            media: [],
          }

          if (includes.media) {
            tweet.media = includes.media
          }
          // eslint-disable-next-line prefer-destructuring
          tweet.user = includes.users[0]

          tweet.user.profile_image_url = tweet.user.profile_image_url.replace('_normal', '_400x400')

          this.tweet = tweet

          this.error = null
          this.loadingToggle()
          this.setLog(true)
        })
        .catch(err => {
          this.error = err.response.data.error
          this.loadingToggle()
        })
        .finally(() => {
          this.fitContent()
        })
    },

    // process text
    processText(data) {
      let { text } = data
      if (!data.entities) return text
      if (data.entities.hashtags) {
        data.entities.hashtags.forEach(tag => {
          text = text.replace(`#${tag.tag}`, `<span class="text__tag link-color">#${tag.tag}</span>`)
          text = text.replace(` #${tag.tag} `, `<span class="text__tag link-color">#${tag.tag}</span>`)
        })
      }
      if (data.entities.urls) {
        data.entities.urls.forEach(url => {
          // url.display_url is like 'pic.twitter.com' just remove it
          if (url.display_url.search('pic.twitter.com') >= 0) {
            text = text.replace(url.url, '')
          } else {
            text = text.replace(
              `${url.url}`,
              `<a href="${url.unwound_url}" class="text__tag link-color">${url.display_url}</a>`,
            )
          }
        })
      }
      if (data.entities.mentions) {
        data.entities.mentions.forEach(mention => {
          text = text.replace(`@${mention.username}`, `<span class="text__tag link-color">@${mention.username}</span>`)
          text = text.replace(
            ` @${mention.username} `,
            `<span class="text__tag link-color">@${mention.username}</span>`,
          )
        })
      }

      return text
    },

    // process emojis
    parseEmoji(string) {
      return this.$twemoji.parse(string, emoji => `https://twemoji.maxcdn.com/v/13.1.0/svg/${emoji}.svg`)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweet-task.scss';
</style>
