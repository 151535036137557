<template>
  <v-card :class="`tweet__card  w-full p-2  tweet__theme__2 card__background  disable_tweet__card__${card.theme} `">
    <div
      ref="tweetContent"
      class="content text__primary"
      v-html="tweet.text"
    ></div>

    <div
      v-if="tweet.media && card.selectedSize.height > 1080"
      class="media"
    >
      <img
        v-for="media in tweet.media"
        :key="media.id"
        :src="media.url"
      >
    </div>

    <v-card-title>
      <v-avatar :size="card.selectedSize.height > 630 ? '110' : '66'">
        <v-img :src="tweet.user.profile_image_url"></v-img>
      </v-avatar>
      <p
        class="ml-3 mt-3 tweet__username"
      >
        {{ tweet.user.name }}
        <v-icon
          v-if="tweet.user.verified"
          class="twitter__verified"
        >
          {{ icons.mdiCheckDecagram }}
        </v-icon>
        <br>
        <small class="text__secondary">@{{ tweet.user.username }}</small>
      </p>
      <v-icon class="twitter__icon">
        {{ icons.mdiTwitter }}
      </v-icon>
    </v-card-title>
  </v-card>
</template>

<script>
import Theme from '@/views/social/mixins/Theme'

export default {
  mixins: [Theme],
  data() {
    return {
      heights: [
        {
          on: 630,
          allowed: 380,
          fontSize: 18,
          minFontSize: 12,
        },
        {
          on: 1080,
          allowed: 680,
          fontSize: 24,
          minFontSize: 14,
        },
        {
          on: 1920,
          allowed: 1420,
          fontSize: 36,
          minFontSize: 14,
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweet-task.scss';
@import '@/styles/tweetshots/theme.scss';

.tweet__theme__2 {
  padding: 1rem;
  .content {
    border-radius: 8px;
    margin: 18px;
    white-space: pre-line;
    overflow: hidden;
    padding: 1.4rem;
    border: 2px solid red;
    box-shadow: 0 2px 0 0 rgba(1, 1, 1, 0.1);
    position: relative;
    color: #666;
    &:before,
    &:after {
      position: absolute;
      visibility: visible;
      width: 0;
      height: 0;
      content: '';
      border-style: solid;
      border-color: transparent;
      border-bottom: 0;
    }
    &:before {
      border-width: 17px;
      border-top-color: rgba(192, 169, 169, 0.26);
      bottom: -16px;
      left: 34px;
    }
    &:after {
      border-width: 15px;
      border-top-color: red;
      bottom: -15px;
      left: 34px;
    }
  }
  .twitter__icon {
    position: absolute;
    right: 2rem;
    // color: #1ea0f1;
  }
}

.height__630 {
  .tweet__card {
    width: 45rem;
    .date,
    .response {
      font-size: 1.2em;
    }
  }
}

.height__1080,
.height__1920 {
  .tweet__card {
    max-width: 90%;

    .tweet__username {
      font-size: 1.5em;
    }
    .twitter__verified,
    .twitter__icon {
      transform: scale(1.5);
      width: 50px;
    }
    .date,
    .response {
      font-size: 1.5em;
    }
  }
}
</style>
