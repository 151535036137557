const Theme = {
  props: ['card', 'tweet', 'icons'],

  computed: {

    styles() {

      // const { textColor } = this.$store.state.social

      // return textColor ? `color:${textColor} !important` : ''
    },

    formatedCreatedAt() {
      const date = new Date(this.tweet.created_at)
      const hours = (`0${date.getHours()}`).slice(-2)
      const mins = (`0${date.getMinutes()}`).slice(-2)

      // 9:06 PM · 3 Feb, 2022
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

      return `${hours}:${mins} · ${date.getDate()} ${
        months[date.getMonth()]
      }, ${date.getFullYear()}`
    },
  },
}

export default Theme
