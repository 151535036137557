<template>
  <div>
    <div
      class="mt-5 mb-2"
    >
      Background Image
    </div>

    <v-card
      style="height:90px"
      outlined
    >
      <v-icon
        v-if="myBackgroundSelected"
        class="float-right mx-4 my-2"
        size="20"
        @click="applyDefaultBackground(defaultBackgrounds[0])"
      >
        {{ icons.mdiClose }}
      </v-icon>
      <div
        class="d-flex pointer"
        @click="showCard = !showCard"
      >
        <v-img
          v-if="myBackgroundSelected"
          max-width="120"
          height="88"
          :src="resolveBackgroundImage"
        >
        </v-img>
        <div
          v-else
          :style="`width:140px;height:88px;${activeBackground}`"
        ></div>
        <v-card-text class="d-flex flex-column justify-center pb-0">
          <span class="text-lg">Background image</span>
          <span
            v-if="!myBackgroundSelected"
            class="text-xs"
          >No file uploaded</span>
        </v-card-text>
      </div>
    </v-card>
    <Transition name="slide-fade">
      <v-card
        v-if="showCard"

        outlined
        class="mt-2"
      >
        <v-card-subtitle>
          <v-icon
            size="20"
            class="float-right"
            @click="showCard=false"
          >
            {{ icons.mdiClose }}
          </v-icon>

          Defaut backgrounds
        </v-card-subtitle>
        <v-card-text
          class="colors d-flex flex-wrap "
        >
          <div
            v-for="(style,index) in defaultBackgrounds"
            :key="index"
            class="box ml-1 mb-1"
            :style="style"
            @click="applyDefaultBackground(style)"
          ></div>
        </v-card-text>

        <v-card-text>
          <!-- default background colors -->
          <div class="default__backgrounds d-flex flex-wrap ">
            <div
              v-for="(style,index) in defaultBackgrounds"
              :key="index"
              class="box"
              :style="style"
              @click="applyDefaultBackground(style)"
            ></div>
          </div>

          <v-divider
            class="mb-4 mt-2"
          ></v-divider>

          <!-- my backgrounds -->
          <v-row
            dense
            class="mb-4 "
          >
            <v-col
              cols="12"
            >
              My Backgrounds
            </v-col>
            <v-col
              v-for="background in myBackgrounds"
              :key="background.id"
              :cols="4"
            >
              <v-card
                class="pointer"
              >
                <v-img
                  :src="background.background_path"
                  @click="applyMyBackground(background)"
                ></v-img>

                <v-card-text style=" position: relative ">
                  <v-fab-transition>
                    <v-btn
                      color="red p-0"
                      absolute
                      top
                      right
                      small
                      fab
                      @click="deleteBackground(background.id)"
                    >
                      <v-icon>{{ icons.mdiDeleteCircle }}</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <input
            ref="refInputFile"
            type="file"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
            @input="uploadBackground($event)"
          />
          <v-btn
            small
            elevation="5"
            class="mr-2"
            primary
            icon
            @click="$refs.refInputFile.click()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
          New Background
        </v-card-text>
      </v-card>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiPlus, mdiDeleteCircle, mdiClose, mdiArrowLeftThick } from '@mdi/js'

export default {
  data() {
    return {
      showCard: false,
      icons: {
        mdiPlus,
        mdiDeleteCircle,
        mdiArrowLeftThick,
        mdiClose,
      },
    }
  },
  computed: {
    ...mapState({
      myBackgrounds: state => state.social.myBackgrounds,
      myBackgroundSelected: state => state.social.myBackground,
      backgroundDialg: state => state.social.backgroundDialg,
      defaultBackgrounds: state => state.social.defaultBackgrounds,
      activeBackground: state => state.social.activeBackground,
    }),
    resolveBackgroundImage() {
      return this.myBackgroundSelected
        ? this.myBackgroundSelected.background_path
        : require('@/assets/images/tweetshot/image-placeholder.svg')
    },
  },
  methods: {
    ...mapActions('social', [
      'backgroundDialgToggle',
      'deleteBackground',
      'uploadBackground',
      'applyDefaultBackground',
      'applyMyBackground',
    ]),
  },
}
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.colors {
  .feature__card {
    cursor: pointer;
    min-width: 7rem;
    .v-card__title {
      font-size: 0.9rem;
      padding: 10px;
    }
    .v-card__subtitle {
      font-size: 0.8rem;
      padding: 10px;
    }
  }

  .box {
    cursor: pointer;
    border-radius: 50%;
    padding: 1px;
    width: 40px;
    height: 40px;
    border: 1px solid grey;
    display: flex;
    &:hover {
      border: 2px solid black;
    }
  }
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
