import { render, staticRenderFns } from "./Theme7.vue?vue&type=template&id=0f9bd8e3&scoped=true&"
import script from "./Theme7.vue?vue&type=script&lang=js&"
export * from "./Theme7.vue?vue&type=script&lang=js&"
import style0 from "./Theme7.vue?vue&type=style&index=0&id=0f9bd8e3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f9bd8e3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VCard,VCardText,VCardTitle,VIcon,VImg})
